import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Category, CostBreakdown } from "../utils/CostBreakdown.jsx";
import useOwnFormulas from "../../hooks/useOwnFormulas";
import * as Actions from "../../redux/actions";
import useErrorHandler from "../../hooks/useErrorHandler";
import firebase from "firebase/app";
import { OWN_FORMULAS } from "../../data";
import toast from "react-hot-toast";
import fertilizerIcon7 from "../../assets/images/fertilizerIcon7.png";
import saveIcon from "../../assets/images/saveIcon.png";
import { InputNumber } from "primereact/inputnumber";
import { Droppable } from "react-beautiful-dnd";
import {
  sumKeys,
  sumResultsApplyingPercentage,
} from "../../calculation/results-utils";
import { useDeepCompareMemoized } from "../../utils";
import {
  useLocalFormulaResults,
  usePlacesOfBlending,
  useFinalDestinations,
} from "../../hooks";
import { orGet } from "../../calculation/results-utils";
import Tooltip from "../../../src/components/utils/Tooltip";
import { auth } from "../../services/firebase";
import XIcon from "../../../src/assets/images/XIcon.svg";
import { useTranslation } from "react-i18next";

export default function Formula({
  formula,
  discardFormulas,
  removeProductFormula,
  editing,
  productTypes,
  setIsResultsOn,
  setIsListIngredientsOn,
  dropdownSelectedPOE,
  dropdownSelectedPOP,
  dropDownSelectedDestination,
  fillers,
  updateFormulas,
  setFormulas,
  idFormulaFirebase,
  isResultsOn,
  usedNutrients,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isClicking, setIsClicking] = useState(editing ? true : false);
  const [editingName, setEditingName] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [totalPriceAtDestination, setTotalPriceAtDestination] = useState(false);
  const newFormula = {
    ...formula,
    products: formula.products.map((product) => {
      return {
        ...product,
        port:
          dropdownSelectedPOE && dropdownSelectedPOE.id
            ? dropdownSelectedPOE.id
            : null,
      };
    }),
  };
  const name = useSelector(() => newFormula.name);
  const id = useSelector((state) => state.formula.id);
  const currency = useSelector((state) => state.currency.currency);
  const fcfa = useSelector((state) => state.currency.fcfa);
  const ghs = useSelector((state) => state.currency.ghs);
  const ownFormulas = useOwnFormulas(true);
  const isOwnFormula = !id || ownFormulas.some((it) => it.id === id);
  const saveErrorHandler = useErrorHandler("Could not save your formula");
  const firestore = firebase.firestore();
  const collection = firestore.collection("formulas");
  const products = useSelector(() =>
    formula.products.map((product) => {
      return {
        ...product,
        port:
          dropdownSelectedPOE && dropdownSelectedPOE.id
            ? dropdownSelectedPOE.id
            : null,
      };
    })
  );
  const isPublic = useSelector((state) => state.formula.public);
  const [filter, setFilter] = useState(Object.values(Category));
  const [showOnlySummary, setShowOnlySummary] = useState(false);

  const results = useLocalFormulaResults(
    editing,
    newFormula,
    dropdownSelectedPOE?.id ? dropdownSelectedPOE.id : undefined,
    dropDownSelectedDestination?.id
      ? dropDownSelectedDestination.id
      : undefined,
    dropdownSelectedPOP?.id ? dropdownSelectedPOP.id : undefined
  );

  const total = React.useMemo(() => {
    return results && results.results.length > 0
      ? [sumResultsApplyingPercentage(results.results.map((it) => it.MAX))]
      : [];
  }, [useDeepCompareMemoized([results])]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setEditingName(!editingName);
    }
  };

  function updateListOfFormulas(e) {
    formula.name = e;
    dispatch(Actions.updateName(e));
  }

  function uptadeFormulaPercentage(e, index, type) {
    const productsUpdate = newFormula.products.map((element, index) => {
      if (element.type === type) {
        dispatch(Actions.updateProduct(index, { ...element, percentage: e }));
        return {
          ...element,
          percentage: e,
        };
      }
      return element;
    });

    updateFormulas({
      ...newFormula,
      products: productsUpdate,
    });
  }

  function onFocus() {}

  function onBlur() {
    setEditingName(!editingName);
  }

  const nameEditor = (
    <input
      className="EditNameField"
      value={name}
      onChange={(e) => updateListOfFormulas(e.target.value)}
      onKeyPress={handleKeyPress}
      autoFocus
      onBlur={() => onBlur()}
      onFocus={() => onFocus()}
      maxLength={50}
    />
  );

  function toggleNameEditor() {
    setEditingName(!editingName);
  }

  function discardFormula() {
    discardFormulas(newFormula);
    setIsClicking(!isClicking);
  }

  function create(payload) {
    collection
      .add(payload)
      .then(({ id }) => {
        OWN_FORMULAS.forget();
        localStorage.removeItem("@fcc/ownFormulas");
        toast.success("Your formula was saved successfully!");
      })
      .catch(saveErrorHandler);
  }

  async function handleAskSaveFirestore() {
    const data = {
      name,
      public: isPublic,
      products,
      email: firebase.auth().currentUser.email,
      typeParam: products.map((e) => e.type),
    };
    console.log("handleAskSaveFirestore", data);

    let percentageSum = 0;

    for (let i = 0; i < products.length; i++) {
      percentageSum = percentageSum + parseFloat(products[i].percentage);
    }

    if (percentageSum > 100)
      toast.error(
        `The sum of the percentages is ${percentageSum}%. Expected 100%.`
      );
    else {
      if (idFormulaFirebase) {
        console.log("idFormulaFirebase", idFormulaFirebase);
        const docRef = collection.doc(idFormulaFirebase);
        docRef
          .get()
          .then((docSnapshot) => {
            if (docSnapshot.exists) {
              docRef
                .set(data)
                .then(() => {
                  OWN_FORMULAS.forget();
                  localStorage.removeItem("@fcc/ownFormulas");
                  toast.success("Your formula was saved successfully!");
                })
                .catch(saveErrorHandler);
            } else {
              create(data);
            }
          })
          .catch(saveErrorHandler);
      } else {
        const publicFormula = await firestore
          .collection("formulas")
          .where("public", "==", true)
          .get();

        const formulasPublic = publicFormula.docs.map((it) => ({
          id: it.id,
          ...it.data(),
        }));

        const myFormula = await firestore
          .collection("formulas")
          .where("email", "==", auth.currentUser?.email)
          .get();

        const formulasMy = myFormula.docs.map((it) => ({
          id: it.id,
          ...it.data(),
        }));

        const formulasMyPublic = [...formulasMy, ...formulasPublic];

        const findNameEqual = formulasMyPublic.find((e) => e.name === name);

        if (findNameEqual) {
          toast.error("You already have a formula with this name", {
            icon: "⚠️",
          });
        } else {
          create(data);
        }
      }
    }
  }

  function openModal(index) {
    dispatch(Actions.uiEditProduct(index));
    dispatch(
      Actions.loadFormula(
        index,
        newFormula.name,
        products,
        false,
        newFormula.orderFormula
      )
    );
  }

  function openModalDelete() {
    setIsOpenModalDelete(!isOpenModalDelete);
  }

  function deleteMyFormulaUnit(formulaDelete) {
    console.log("deleteMyFormulaUnit");
    try {
      collection
        .doc("SOZiYI5WW4lDmO23qawz")
        .delete()
        .then(() => {
          OWN_FORMULAS.forget();
          localStorage.removeItem("@fcc/ownFormulas");
          dispatch(Actions.clearFormula());
          setFormulas([]);
          toast.success(formulaDelete.name + " was discarded");
        })
        .catch((err) => {
          console.error(err);
          toast.error("Failed to delete formula");
        });
    } catch (err) {
      toast.error("Failed to delete formula");
    }
    setIsOpenModalDelete(false);
  }

  function extractValue(selector) {
    const data = [
      ...total,
      ...(showOnlySummary
        ? results.results
            .map((it) => it.MAX)
            .map((result) =>
              Object.fromEntries(
                Object.entries(result).filter(([k]) => sumKeys.includes(k))
              )
            )
        : []),
    ];

    function select(it) {
      const selected = selector(it);

      if (selected === "") return 0.0;
      else if (
        selected === undefined ||
        (isNaN(selected) && typeof selected !== "string")
      ) {
        return undefined;
      } else {
        return typeof selected === "number" ? selected.toFixed(2) : selected;
      }
    }

    if (data[0]) {
      return data.map((it, index) => {
        const selectedValue = select(it);
        if (selectedValue === undefined) {
          return undefined;
        } else {
          return select(it);
        }
      });
    } else {
      return "";
    }
  }

  const nutrients = [
    "N",
    "P2O5",
    "K2O",
    "Ca",
    "Mg",
    "S",
    "Zn",
    "B",
    "Cu",
    "Cl",
  ];

  console.log(usedNutrients);
  return (
    <>
      <div
        className="formulas"
        key={newFormula.id}
        style={{ boxShadow: "red" }}
      >
        <div className="FormulasUnit">
          <div className="FormulaName">
            <img src={fertilizerIcon7} alt="" />
            {editingName ? (
              nameEditor
            ) : (
              <p className="NameTitle">{newFormula.name}</p>
            )}
            {isOwnFormula && editingName
              ? null
              : isClicking && (
                  <Tooltip overlay="Edit Name">
                    <div>
                      <button className="pencilImage">
                        <i
                          className="fa fa-pencil-alt"
                          onClick={() => toggleNameEditor()}
                          style={{
                            color: "#04ba56",
                            fontSize: ".95rem",
                            marginLeft: 5,
                          }}
                        />
                      </button>
                    </div>
                  </Tooltip>
                )}
          </div>
          <div className="EndGroup">
            <div className="suggestedFormula">
              <div>
                <div style={{ "align-items": "center", display: "flex" }}>
                  {products.map((products) => (
                    <div className="Percentages">
                      <div className="div">
                        <div
                          className="littleBall"
                          style={{
                            backgroundColor: fillers.find(
                              (e) => products.type === e.id
                            )
                              ? "#9146fa"
                              : "#49b2f5",
                          }}
                        ></div>
                        <p>
                          {products.percentage}% -{" "}
                          <span>
                            {productTypes.find((it) => it.id === products.type)
                              ?.name || "INVALID"}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    marginTop: 5,
                    marginLeft: 15,
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    justifyContent: "end",
                  }}
                >
                  {usedNutrients && (
                    <>
                      <p>🌿</p>
                      <p>{usedNutrients.join(", ")}</p>
                    </>
                  )}
                </div>
              </div>

              <div className="TotalPrice desktop">
                {extractValue((it) =>
                  orGet(it.wholesalePricesProductionArea)
                ) && (
                  <p>
                    {"Total Price at destination: "}
                    <span>
                      {currency === "USD" &&
                        extractValue(
                          (it) => orGet(it.wholesalePricesProductionArea) / 20
                        )}
                      {currency === "FCFA" &&
                        extractValue(
                          (it) =>
                            orGet(it.wholesalePricesProductionArea) *
                            (fcfa / 20)
                        )}
                      {currency === "GHS" &&
                        extractValue(
                          (it) =>
                            orGet(it.wholesalePricesProductionArea) * (ghs / 20)
                        )}
                    </span>
                  </p>
                )}
              </div>
              <div className="TotalPrice mobile">
                <p
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    color: "#04ba56",
                  }}
                >
                  $
                  {currency === "USD" &&
                    extractValue(
                      (it) => orGet(it.wholesalePricesProductionArea) / 20
                    )}
                  {currency === "FCFA" &&
                    extractValue(
                      (it) =>
                        orGet(it.wholesalePricesProductionArea) * (fcfa / 20)
                    )}
                  {currency === "GHS" &&
                    extractValue(
                      (it) =>
                        orGet(it.wholesalePricesProductionArea) * (ghs / 20)
                    )}
                </p>
              </div>
              <div>
                <button
                  onClick={() => setIsClicking(!isClicking)}
                  style={{ cursor: "pointer" }}
                >
                  {!isClicking ? (
                    <i
                      class="fa fa-chevron-down"
                      aria-hidden="true"
                      style={{
                        color: "#04ba56",
                        fontSize: 15,
                        marginRight: 10,
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-chevron-up"
                      aria-hidden="true"
                      style={{
                        color: "#04ba56",
                        fontSize: 15,
                        marginRight: 10,
                      }}
                    ></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isClicking && (
            <div className="FormulaExpand">
              <div className="FormulaExpandMain">
                <div className="Cards">
                  {products.map((products, index) => (
                    <div
                      className="Card"
                      style={{
                        backgroundColor: fillers.find(
                          (e) => products.type === e.id
                        )
                          ? "#9146fa"
                          : "#49b2f5",
                      }}
                    >
                      <Tooltip overlay="Edit Percentage" placement="left">
                        <p>
                          <InputNumber
                            //inputClassName="inputPercentage"
                            suffix="% -"
                            value={products.percentage}
                            mode="decimal"
                            maxFractionDigits={1}
                            minFractionDigits={1}
                            min={0}
                            max={100}
                            onValueChange={(e) =>
                              uptadeFormulaPercentage(
                                e.target.value,
                                index,
                                products.type
                              )
                            }
                            inputStyle={{
                              color: "#fff",
                              borderRadius: 0,
                              backgroundColor: fillers.find(
                                (e) => products.type === e.id
                              )
                                ? "#9146fa"
                                : "#49b2f5",
                              borderColor: fillers.find(
                                (e) => products.type === e.id
                              )
                                ? "#9146fa"
                                : "#49b2f5",
                              fontWeight: "bold",
                              padding: 0,
                              marginLeft: 4,
                              maxWidth: 70,
                              cursor: "pointer",
                            }}
                          />
                          {productTypes.find((it) => it.id === products.type)
                            ?.name || "INVALID"}
                        </p>
                      </Tooltip>
                      <div className="EndGroup">
                        <Tooltip overlay={t("Edit Ingredient")}>
                          <i
                            className="bx bxs-cog"
                            style={{ color: "white", cursor: "pointer" }}
                            onClick={() => openModal(index)}
                          ></i>
                        </Tooltip>
                        <Tooltip overlay={t("Remove Ingredient")}>
                          <i
                            className="bx bxs-trash-alt"
                            style={{ color: "white", cursor: "pointer" }}
                            onClick={() =>
                              removeProductFormula(formula, products.type)
                            }
                          ></i>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                  <Droppable droppableId={newFormula.name}>
                    {(provided, snapshot) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        <div
                          className="AddIngredient"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            backgroundColor: snapshot.isDraggingOver
                              ? "#04ba56aa"
                              : "#f9f9f9",
                          }}
                        >
                          <p
                            style={{
                              color: snapshot.isDraggingOver
                                ? "#fff"
                                : "#697384",
                            }}
                          >
                            {t(
                              "Add the ingredients by grabbing it at the list of ingredients"
                            )}
                          </p>
                        </div>
                      </ul>
                    )}
                  </Droppable>
                  <div className="mobileIngredientsAndResults">
                    <button
                      className="btn btn--blue"
                      onClick={() => setIsListIngredientsOn(true)}
                    >
                      Ingredients
                    </button>
                    <button
                      className="btn btnOrangeBg w100 dfCenter"
                      style={{ fontWeight: "bold", maxWidth: "100%" }}
                      onClick={() => setIsResultsOn(true)}
                    >
                      Cost Breakdown
                    </button>
                  </div>
                  <div className="ActionButtons">
                    <button
                      className="SaveBtn"
                      onClick={() => handleAskSaveFirestore()}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          gap: "2px",
                        }}
                      >
                        <img src={saveIcon} alt="" />
                        {t("Save")}
                      </div>
                    </button>
                    {!editing && (
                      <button
                        className="btn btn--alert"
                        onClick={() => discardFormula()}
                      >
                        {t("Discard")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="desktop">
                  <CostBreakdown
                    headersTitle="Product *"
                    filter={filter}
                    headers={[
                      newFormula.name,
                      ...(showOnlySummary
                        ? results.results.map(
                            (it) =>
                              `${it.MAX.product.percentage}% ${it.MAX.product.type.name}`
                          )
                        : []),
                    ]}
                    data={[
                      ...total,
                      ...(showOnlySummary
                        ? results.results
                            .map((it) => it.MAX)
                            .map((result) =>
                              Object.fromEntries(
                                Object.entries(result).filter(([k]) =>
                                  sumKeys.includes(k)
                                )
                              )
                            )
                        : []),
                    ]}
                    dropdownSelectedPOE={dropdownSelectedPOE}
                    dropdownSelectedPOP={dropdownSelectedPOP}
                    dropDownSelectedDestination={dropDownSelectedDestination}
                    //totalPriceAtDestination={totalPriceAtDestination}
                    setTotalPriceAtDestination={setTotalPriceAtDestination}
                    margin={"70px 0 0 67px"}
                  />
                </div>
                {isResultsOn && (
                  <div className="modal">
                    <div className="modal--costBreakdown">
                      <button
                        className="modalBtn"
                        onClick={() => setIsResultsOn(false)}
                      >
                        <img src={XIcon} alt="" />
                      </button>
                      <CostBreakdown
                        headersTitle="Product *"
                        filter={filter}
                        headers={[
                          newFormula.name,
                          ...(showOnlySummary
                            ? results.results.map(
                                (it) =>
                                  `${it.MAX.product.percentage}% ${it.MAX.product.type.name}`
                              )
                            : []),
                        ]}
                        data={[
                          ...total,
                          ...(showOnlySummary
                            ? results.results
                                .map((it) => it.MAX)
                                .map((result) =>
                                  Object.fromEntries(
                                    Object.entries(result).filter(([k]) =>
                                      sumKeys.includes(k)
                                    )
                                  )
                                )
                            : []),
                        ]}
                        dropdownSelectedPOE={dropdownSelectedPOE}
                        dropdownSelectedPOP={dropdownSelectedPOP}
                        dropDownSelectedDestination={
                          dropDownSelectedDestination
                        }
                        //totalPriceAtDestination={totalPriceAtDestination}
                        setTotalPriceAtDestination={setTotalPriceAtDestination}
                        margin={"70px 0 0 67px"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpenModalDelete && (
        <div className="modal">
          <div className="modalDelete">
            <h1>{t("Delete formula?")}</h1>
            <p>
              {t(
                "Are you sure you want to permanently delete the selected formula?"
              )}
            </p>
            <div>
              <button
                className="btn btn--secondary"
                onClick={() => openModalDelete()}
              >
                {t("Cancel")}
              </button>
              <Link to="/my/formula">
                <button
                  className="btn btn--alert"
                  onClick={() => deleteMyFormulaUnit(newFormula)}
                >
                  {t("Delete")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
